// --------------------------------------------------------
//
// Layout
//
// --------------------------------------------------------
// 說明 (完整說明見) src/all/all.scss
//	* 此檔案包含：Layout樣式 Loader、Header、Footer

// -------------------------------------------------------

/* Loader */
.loader {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    // background: $light-blue-1 url(../images/loading.svg) no-repeat center center;
    background: url(../images/logo.png) no-repeat center center;
    pointer-events: none;
    background-color: $white;

    // Loaded
    &.loaded {
        transform: scale(1.2);
        opacity: 0;
        transition: transform 1s, opacity 1s 0.5s;
    }
}

// -------------------------------------------------------

/* Header */
.hd {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    width: 100%;
    height: rem-calc(60);
    background-color: $white;

    .ct_ctr {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .hd-logo {
            @include breakpoint (medium down) {
                max-width: rem-calc(90);
            }

            img {
                width: 100%;
            }
        }

        .hd-icon {
            a {
                line-height: 100%;
                color: $black;
                font-weight: $fw-blod;

                &:first-child {
                    margin-right: rem-calc(8);

                    @include breakpoint (large) {
                        margin-right: rem-calc(15);
                    }
                }
            }

            .co-icon {
                font-size: rem-calc(26);
                margin-left: rem-calc(0);

                @include breakpoint (large) {
                    font-size: rem-calc(34);
                    margin-left: rem-calc(5);
                }

                &::before {
                    transform: translateY(rem-calc(3));
                }

                &.fb {
                    color: $fb-color;
                }

                &.yt {
                    color: $yt-color;
                }

                &.ig {
                    color: $ig-color;
                }
            }
        }
    }
}

// --------------------------------------------------------

/* Footer */
.ft {
    position: relative;
    z-index: 999;
    background-color: $black;

    .ct_ctr {
        padding: rem-calc(80 0 0 0);

        .ft_title {
            color: $white;
            font-weight: $fw-blod;
            letter-spacing: rem-calc(2);
            border-bottom: rem-calc(1) solid $white;
            padding-bottom: rem-calc(15);

            @include breakpoint (xlarge only) {
                font-size: rem-calc(26);
            }

            span {
                font-size: rem-calc(14);
                text-align: left;
                display: block;

                @include breakpoint (large) {
                    font-size: rem-calc(14);
                    text-align: right;
                }

                @include breakpoint (medium down) {
                    margin-top: rem-calc(8);
                }
            }
        }

        .ft_article {
            padding: rem-calc(30 0);
            color: $white;
            border-bottom: rem-calc(1) solid $white;

            @include breakpoint (large) {
                padding: rem-calc(60 50);
            }

            p {
                margin: 0;
                line-height: 180%;

                @include breakpoint (medium down) {
                    font-size: rem-calc(13);
                }

                img {
                    margin: rem-calc(30 0);
                }

                a {
                    color: $blackbglink-color;
                    margin: rem-calc(0 5);

                    @include breakpoint (large) {
                        opacity: 0.7;
                        transition: 0.4s;

                        &:hover {
                            opacity: 1;
                            transition: 0.4s;
                        }
                    }
                }
            }
        }

        .ft_last {
            padding: rem-calc(20 0);
            opacity: 0.6;

            @include breakpoint (medium) {
                display: flex;
                justify-content: space-between;
            }

            @include breakpoint (medium down) {
                font-size: rem-calc(13);
            }

            p,
            a {
                color: $white;
                margin: 0;
                display: block;
            }

            a {
                &:last-child {
                    @include breakpoint (large) {
                        transition: 0.4s;

                        &:hover {
                            transition: 0.4s;
                            color: #F6E68A;
                        }
                    }
                }
            }
        }
    }
}
