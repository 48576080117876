// --------------------------------------------------------
//
// Font - 字型
//
// --------------------------------------------------------

/* 中文襯線字 */
// 從 HTML 載入 Google Font 設定
.font-serif {
	font-family: 'Noto Serif TC', serif;
}

.ntt {
	font-family: 'Noto Sans TC', sans-serif;
}

.nthk {
	font-family: 'Noto Sans HK', sans-serif;
}

.ntjp {
	font-family: 'Noto Sans JP', sans-serif;
}
